import { functions, useApp, useWidth } from '@wap-client/core'
import React, { useRef } from 'react'
import Slider from 'react-slick'
import { StgSliderProps } from './type'
import Icon from '@/components/base/icon'
import Image from '@/components/base/image'
import NextImage from 'next/image'
import { Column, Container, Row } from '@/components/base/gridview'
import { RiArrowRightSFill, RiArrowLeftSFill } from 'react-icons/ri'
import { BiCaretRight, BiCaretLeft } from 'react-icons/bi'
import { BsCaretRightFill, BsCaretLeftFill } from 'react-icons/bs'
import rightSvg from './383168_caret_right_icon.svg'

import Button from '@/components/base/button'
import Dots from '../dots/Dots'

const StgSlider: React.FC<StgSliderProps> = ({ items }) => {
  const width = useWidth()
  const app = useApp()
  const slick = useRef<Slider>(null)
  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    infinite: true,
    speed: 900,
    autoplay: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
  }
  return (
    <Container size="fluid" className="stg-slider-container">
      <Row className="stg-slider-row">
        <Column className="stg-slider-column">
          <div className="stg-slider">
            <div
              className={functions.classnames(
                'button-layout button-layout-prev',
                width < 860 && 'is-mobile'
              )}
            >
              <button
                className="arrow-button"
                onClick={() => slick.current?.slickPrev()}
                style={{ position: 'relative' }}
              >
                <NextImage
                  src="/icons/383169_caret_left_icon.svg"
                  alt="Slider Prev Button"
                  fill
                  sizes="100%"
                />
              </button>
            </div>
            <div className="stg-slider-container">
              <Slider {...settings} ref={slick}>
                {items?.map((item, index) => (
                  <div className="StgSlider-slider" key={index}>
                    <div className="stg-slider-slogan">{item.slogan}</div>
                    <div className="stg-slider-content-item">
                      <figure>
                        {width > 860 ? (
                          <Image src={item.image?.src} alt="" />
                        ) : (
                          <Image src={item.mobileimage?.src} alt="" />
                        )}
                      </figure>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div
              className={functions.classnames(
                'button-layout button-layout-next',
                width < 860 && 'is-mobile'
              )}
            >
              <button
                className="arrow-button"
                onClick={() => slick.current?.slickNext()}
                style={{ position: 'relative' }}
              >
                <NextImage
                  src="/icons/383168_caret_right_icon.svg"
                  alt="Slider Next Button"
                  fill
                  sizes="100%"
                />
              </button>
            </div>
          </div>
        </Column>
      </Row>
      <div className="stg-slider-dots">
        <Dots />
      </div>
    </Container>
  )
}

export default StgSlider
